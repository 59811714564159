import React from 'react';
import { Card, CardHeader, Checkbox, CardBody, Loader, Text, Button, Dropdown, Flex } from '@fluentui/react-northstar';
import { CloseIcon, FilesImageIcon } from '@fluentui/react-icons-northstar';
import { RevenueCustomQueryFilter } from '../../../shared/appcomponents/custom-query-filter';
import { BillingDashChart , PieChart, UserGroupsDrillDown, IndustryDrillDown} from '../../../shared/appcomponents/custom-charts';
import {
	Chart,
	ChartTitle,
	ChartSeries,
	ChartSeriesItem,
	ChartCategoryAxis,
	ChartCategoryAxisItem,
	ChartLegend,
	ChartValueAxisItem,
	ChartValueAxis,
	exportVisual,
	ChartBreadcrumb
} from "@progress/kendo-react-charts";
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import 'hammerjs';
import './revenue.scss';
import RevenueService from "../../../services/revenueservice";
import TopKPI from "../revenue/topkpi";

import { OPERATIONSTATUS } from '../../../shared/appcomponents/enums';
const { forwardRef, useRef, useImperativeHandle } = React;

const BusinessPerformanceDashboard = (props) => {
	// console.log(props);
	const [loaded, setLoaded] = React.useState(false);
	const [partBilling, setPartBilling] = React.useState(true);
	const [revenueDashboardData, setRevenueDashboardData] = React.useState(null);
	const [products, setProducts] = React.useState([]);
	const [selectedProduct, setSelectedProduct] = React.useState(null);
	const [appliedFilters, setAppliedFilters] = React.useState(null);
	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "ProductName",
				dir: "asc",
			},
		],
		take: 10,
		skip: 0,
	});
	const [selectedYear, setSeletedYear] = React.useState({ key: 0, header: 'This Year', value: 0 });
	const [topKPIdata, setTopKPIdata] = React.useState(null);

	const [downloading, setDownloading] = React.useState(false);

	const [showChartPopup, SetShowChartPopup] = React.useState(false);

	const [chartDetailsData, SetChartDetailsData] = React.useState({});

	const [showChartPopup2, SetShowChartPopup2] = React.useState(false);

	const [chartDetailsData2, SetChartDetailsData2] = React.useState({});
	const [defaultCustomers, SetDefaultCustomers] = React.useState({});
	// const [updateId, setUpdateId] = React.useState("123");

	const RecurringBilling = React.useRef(null);
    const GrowththisFYRecurringBilling = React.useRef(null);
    const ChurnedBilling = React.useRef(null);
    const TenantsAdded = React.useRef(null);
    const TenantsRemoved = React.useRef(null);
    const EndPoints = React.useRef(null);
    const TotalsEstateTenants = React.useRef(null);
    const TotalsEstateEndPoints = React.useRef(null);
    const NumberofCallRecordings = React.useRef(null);
    const CallRecordingMinutes = React.useRef(null);
    const RecordingStorage = React.useRef(null);

	React.useEffect(() => {

		let year = 0;
		let currentMonth = (new Date()).getMonth();
		if (currentMonth == 3) { // for April defalut ddl val is prev year
			setSeletedYear({ key: 1, header: 'Financial Year ' + getFY(1), value: 1 }); // for April defalut ddl val is prev year
			year = 1;
		}

		props.acquireToken((token) => {
			if (token) {
				let _params = {
					year: year,
					appliedFilters: null,
					isRevenue: '0',
					isPartMonthBillingIncluded: year == 0 ? partBilling : false,
					tryCache: true
				};

				setLoaded(false);
				RevenueService.GetRevenueDashboard(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						response?.TenantChart?.map((t) => {
							t.Date = new Date(t.BillMonth);
						});
						let groupedValues = {};
						response?.LicenceChart?.map(t => {
							groupedValues[t.ProductCode] != undefined ? groupedValues[t.ProductCode].push(t) : groupedValues[t.ProductCode] = [t];
						});
						response.LicenceChart = groupedValues;
						let _products = Object.keys(response.LicenceChart).map(m => ({ key: m, header: m, value: m }));
						setProducts(_products);
						setSelectedProduct(_products[0]);
						setRevenueDashboardData(response);
						SetDefaultCustomers(response.DefaultCustomers);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status)
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					setLoaded(true);
					// setUpdateId(Math.random().toString());
				});
			}
		});
		//props.acquireToken(authSuccessCallback);
	}, []);


	const refreshData = (_selectedYear, _partBilling) => {

		setLoaded(false);
		setRevenueDashboardData(null);
		props.acquireToken((token) => {
			let _params = {
				year: _selectedYear.value,
				appliedFilters: appliedFilters,
				isRevenue: "0",
				isPartMonthBillingIncluded: _selectedYear?.value == 0 ? _partBilling : false,
				tryCache: false
			};
			if (token) {
				RevenueService.GetRevenueDashboard(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						response?.TenantChart?.map((t) => {
							t.Date = new Date(t.BillMonth);
						});
						let groupedValues = {};
						response?.LicenceChart?.map(t => {
							groupedValues[t.ProductCode] != undefined ? groupedValues[t.ProductCode].push(t) : groupedValues[t.ProductCode] = [t];
						});
						response.LicenceChart = groupedValues;
						let _products = Object.keys(response.LicenceChart).map(m => ({ key: m, header: m, value: m }));
						setProducts(_products);
						setSelectedProduct(_products[0]);
						setRevenueDashboardData(response);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					setLoaded(true);
				});
			}
		})
	}
	const handleFYChange = (_selectedYear) => {
		setSeletedYear(_selectedYear);
		if (_selectedYear.value == 0) {
			setPartBilling(true);
			refreshData(_selectedYear, true);
		}
		else {
			setPartBilling(false);
			refreshData(_selectedYear, true);
		}
	}

	const handleFilterUpdate = (_appliedFilters) => {
		setLoaded(false);
		setRevenueDashboardData(null);
		setAppliedFilters(_appliedFilters);
		props.acquireToken((token) => {
			let _params = {
				year: selectedYear.value,
				appliedFilters: _appliedFilters,
				isRevenue: "0",
				isPartMonthBillingIncluded: selectedYear?.value == 0 ? partBilling : false,
				tryCache: false
			};
			if (token) {
				RevenueService.GetRevenueDashboard(token, _params).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						response?.TenantChart?.map((t) => {
							t.Date = new Date(t.BillMonth);
						});
						let groupedValues = {};
						response?.LicenceChart?.map(t => {
							groupedValues[t.ProductCode] != undefined ? groupedValues[t.ProductCode].push(t) : groupedValues[t.ProductCode] = [t];
						});
						response.LicenceChart = groupedValues;
						let _products = Object.keys(response.LicenceChart).map(m => ({ key: m, header: m, value: m }));
						setProducts(_products);
						setSelectedProduct(_products[0]);
						setRevenueDashboardData(response);
					}
					else if (response && response.status == 401) {
						props.viewStatus.InformChildPageStatus(response.status);
					}
					else {
						props.viewStatus.InformChildPageStatus(500);
					}
					setLoaded(true);
				});
			}
		})
	}

	const filterGridData = (value) => {
		let filter = {
			logic: 'or',
			filters: [
				{ field: 'ProductCode', operator: 'contains', value: value },
				{ field: 'ProductName', operator: 'contains', value: value }
			],
		};
		setDataState({ ...dataState, filter: filter });
	};

	const rowRender = (trElement, props) => {
		const credit = props.dataItem.ProductCode.startsWith('-');
		const churned = props.dataItem.GrowthChurnQuantity < 0;

		let className = credit ? 'p-credit ' : '';
		className += churned ? 'p-churned' : '';
		const trProps = {
			class: className
		};
		return React.cloneElement(
			trElement,
			{ ...trProps },
			trElement.props.children
		);
	};

	const categories = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];

	const chartDefaultV4Colors = [
		"#28a745",
		"#dc3545",
	];

	const chartColorsWarning = [
		"#dc3545",
	];

	const downloadExcelHandler = () => {
		props.acquireToken((token) => {
			if (token) {
				setDownloading(true);
				RevenueService.DownloadRevenueExcel(token).then((response) => {
					if (response && response.status == 200) {
						response = response.data;
						const href = URL.createObjectURL(response);

						// create "a" HTML element with href to file & click
						const link = document.createElement('a');
						link.href = href;
						link.setAttribute('download', 'revenue_data.xlsx'); //or any other extension
						document.body.appendChild(link);
						link.click();

						// clean up "a" element & remove ObjectURL
						document.body.removeChild(link);
						URL.revokeObjectURL(href);
					}
					else if (response && response.status == 401) {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
					}
					else {
						props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
					}
					setDownloading(false);
				});

			}
		});
	}

	const seriesClickHandler = (category) => {
		SetChartDetailsData(category);
		SetShowChartPopup2(false);
		SetShowChartPopup(true);
		// childRef.current.getAlert();
	}

	const seriesClickHandler2 = (category) => {
		SetChartDetailsData2(category);
		SetShowChartPopup(false);
		SetShowChartPopup2(true);
		// childRef.current.getAlert();
	}

	const CloseChartDetails = () => {
		SetShowChartPopup(false);
		SetShowChartPopup2(false);
		// childRef.current.Close();
	}

	const pageClick = () => {
		SetShowChartPopup(false);
		SetShowChartPopup2(false);
		// childRef.current.Close();
	}

	const pageScroll = () => {
		console.log('page pageScroll');
	}

	const churnAxisCrossingValue = [0, 14];

	let labelRotation = "0";

	if (revenueDashboardData?.ChartData.CategorySeries && revenueDashboardData?.ChartData.CategorySeries.length > 8) {
		labelRotation = "45"
	}

	const childRef = useRef();

	const getFY = (i) => {
		let currentYear = (new Date()).getFullYear();
		let currentMonth = (new Date()).getMonth();
		let fy = currentYear;

		if (currentMonth < 3) {
			fy = currentYear - 1;
		}

		if (i == 1) {
			fy = fy - 1;
		}
		else if (i == 2) {
			fy = fy - 2;
		}

		let nextYear = fy + 1;

		return (fy.toString() + '-' + nextYear.toString().substring(2, 4));

	}

	const dropDownItems = [{ key: 0, header: 'This Year', value: 0 }, { key: 1, header: 'Financial Year ' + getFY(1), value: 1 }, { key: 2, header: 'Financial Year ' + getFY(2), value: 2 }];

	const ddc = (props) => {
		debugger
		const v = props.drilldownValue;
		return (
			<ChartSeriesItem
				tooltip={{ visible: true }}
				name={`Customer Distribution by Industry (User Count: ${props.drilldownValue})`}
				data={revenueDashboardData?.ChartData?.UserGroupsIndustries[v]}
				field="Size"
				categoryField="DisplayName" color="#004B95"
				labels={{
					rotation: "270",
				}}
				{...props}
			/>
		);
	};

	const industry_ddc = (props) => {
		debugger
		const v = props.drilldownValue;
		return (
			<ChartSeriesItem
				tooltip={{ visible: true }}
				name={`Customer Distribution by User Group`}
				data={revenueDashboardData?.ChartData?.IndustryDetails[v]}
				field="Size"
				categoryField="Name" color="#004B95"
				labels={{
					rotation: "270",
				}}
				{...props}
			/>
		);
	};

	const ChartLabel = (chartProps) => {
		return (
			<>
				Customer Distribution by User Group (Industry:)
			</>
		);
	
	};
	


	return (
		<div onClick={pageClick} onScroll={pageScroll}>
			<div className='fluid w-100'>
				<div className='row applied-sec business'>
					<div className='col'>
						<Flex vAlign='center' fluid className='w-100 mb-2 pr-2'>

							<RevenueCustomQueryFilter {...props} handleFilterUpdate={handleFilterUpdate}
								elegibleFilters={['Instance', 'ProductStream', 'Customer', 'Product', 'Region']}
								isRevenue="0"
							// key={updateId} 
							/>
							<Flex.Item push >
								<div className='fy-ddl'>
									<Dropdown value={selectedYear} items={dropDownItems}
										onChange={(e, selectedItem) => {
											handleFYChange(selectedItem.value);
										}}
										fluid
									/>
								</div>
								
							</Flex.Item>
							{
								
									<Checkbox disabled={selectedYear?.value == 0 ? false : true} size="small"
										className="form-checkbox part-billing-checkbox"
										label="Part Month Billing"
										toggle
										style={{ margin: '0 -6px 0 23px' }}
										onChange={(e, { checked }) => {
											setPartBilling(checked);
											refreshData(selectedYear, checked)
										}}
										checked={selectedYear?.value == 0 ? partBilling : true } />
							}
							{/* <div className='icnTrk ml-2'>
															<Button title='Download PDF' icon={<FilesPdfIcon outline rotate={0} size="large" />} iconOnly />
													</div>
													 */}
						</Flex>
					</div>
				</div>
				{
					loaded ?
						<div>
							<TopKPI {...props} data={revenueDashboardData.TopKPI} businessInsights={revenueDashboardData?.BusinessInsights} year={selectedYear?.value} isRevenue="0" />

							<div className='row'>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Customers by Size" />
										</CardHeader>
										<CardBody>
										<UserGroupsDrillDown MainData={revenueDashboardData?.ChartData?.UserGroupsCustomerCount} 
												SecondData={revenueDashboardData?.ChartData?.UserGroupsIndustries}
												DrilldownByCompany={ddc}
											/>
											{/* <BillingDashChart categories={[{title: "Users Count", labelRotation: "270", data: revenueDashboardData?.ChartData.CustomerSizeName}]}
												series={[{type: "column", data: revenueDashboardData?.ChartData.CustomerSizeValue, color: "#004B95"  }]}
												valueAxis={ {title:"No. of Customers"}}
											/>											 */}
										</CardBody>
									</Card>
								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Customers by Industry" />
										</CardHeader>
										<CardBody>

										<IndustryDrillDown MainData={revenueDashboardData?.ChartData?.IndustryCustomerCount} 
												// SecondData={revenueDashboardData?.ChartData?.UserGroupsIndustries}
												DrilldownByCompany={industry_ddc}
											/>

										{/* <UserGroupsDrillDown MainData={revenueDashboardData?.ChartData?.IndustryCustomerCount} 
												SecondData={revenueDashboardData?.ChartData?.UserGroupsIndustries}
												DrilldownByCompany={ddc}
											/> */}


											{/* <BillingDashChart categories={[{title: "Industry(Top 25)", labelRotation: "270", data: revenueDashboardData?.ChartData.IndustryNames}]}
												series={[{type: "column", data: revenueDashboardData?.ChartData.IndustryCustomersCount, color: "#004B95" }]}
												valueAxis={ {title:"No. of Customers"}}
											/> */}
										</CardBody>
									</Card>
								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Top 25 Customers by Size" />
										</CardHeader>
										<CardBody>
										{/* <PieChart title="By Customer" categoryField="Name" field="Size" data={revenueDashboardData?.ChartData.CustomersChartInfo} /> */}
										<BillingDashChart 
											categories={[{title: "Customers", labelRotation: "270", 
													data: revenueDashboardData?.ChartData.Top25CustomerNames, 
													}]}
												series={[{type: "column", data: revenueDashboardData?.ChartData.Top25CustomerSize, color: "#004B95" }]}
												valueAxis={ {title:"No. of Users"}}
											/>
										</CardBody>
									</Card>
								</div>

								

							</div>



							<div className='row d-none'>
								
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Customers by Size" />
										</CardHeader>
										<CardBody>
											{/* <IndustryDrillDown MainData={revenueDashboardData?.ChartData?.UserGroupsCustomerCount} 
												// SecondData={revenueDashboardData?.ChartData?.UserGroupsIndustries}
												DrilldownByCompany={industry_ddc}
											/> */}
										{/* <BillingDashChart categories={[{title: "Customers", labelRotation: "270", data: revenueDashboardData?.ChartData.Top25CustomerNames}]}
												series={[{type: "column", data: revenueDashboardData?.ChartData.Top25CustomerSize, color: "#f0ca29" }]}
											/> */}
										</CardBody>
									</Card>
								</div>

							</div>


							<div className='row'>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="% Recurring Billing" />
										</CardHeader>
										<CardBody>
											<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", data: revenueDashboardData?.ChartData.PercentRecurringRevenue, color: "#f0ca29" }]}
											/>											
										</CardBody>
									</Card>
								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Growth this FY Recurring Billing" />
										</CardHeader>
										<CardBody>
											<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", data: revenueDashboardData?.ChartData.GrowthThisFY_RR, color: "#f0ca29" }]}
											/>
										</CardBody>
									</Card>
								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Churned Billing" />
										</CardHeader>
										<CardBody>
											<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries, axisCrossingValue: churnAxisCrossingValue }]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.ChurnBilling, name:"Churned Billing", axis:"TotalChurned", color:"#dc3545" },
													{type: "line", data: revenueDashboardData?.ChartData.ChurnBillingPercent, name:"% Recurring Billing Churn", axis:"PercentChurned", color:"#e077ae" }]}
												values={[{name: "TotalChurned"}, {name: "PercentChurned", position:"right"}]}
											/>
										</CardBody>
									</Card>
								</div>

							</div>

							<div className='row'>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Tenants" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", name:"Tenants Added", gap: 4, data: revenueDashboardData?.ChartData.TenantsAdded, color: "#28a745" }]}
											/>
										</CardBody>
									</Card>
									{/* {
										<ChartDetails showChartPopup={showChartPopup} CloseChartDetails={CloseChartDetails} data={chartDetailsData} ></ChartDetails>
										// <ChartDetails2   ref={childRef}  ></ChartDetails2>
									} */}

								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Tenants" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", name:"Tenants Removed %", gap: 4, data: revenueDashboardData?.ChartData.TenanatsRemoved, color: "#dc3545" }]}
											/>
										</CardBody>
									</Card>
								</div>

								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="End Points" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", name:"End Points Added", gap: 4, data: revenueDashboardData?.ChartData.EndPointsAdded, color: "#28a745" },
												{type: "column", name:"End Points Removed", data: revenueDashboardData?.ChartData.EndPointsRemoved, color: "#dc3545" }]}
											/>
										</CardBody>
									</Card>
								</div>
							</div>



							<div className='row'>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Totals Estate -Tenants,Users" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", name:"Total Tenants (in thousands)", data: revenueDashboardData?.ChartData.EstateTotalTenants, color: "#28a745" },
												{type: "line", name:"Total Users (in thousands)", data: revenueDashboardData?.ChartData.EstateTotalUsers, color: "#f0ca29" }]}
												valueAxis={{min: revenueDashboardData?.ChartData.EstateTotalChart1Min}}
											/>
										</CardBody>
									</Card>

								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Totals Estate -End Points" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "line", name:"Total End Points  (in thousands)", data: revenueDashboardData?.ChartData.EstateTotalEndPoints, color: "#28a745" },
												{type: "line", name:"Total Recording End Points  (in hundreds)", data: revenueDashboardData?.ChartData.EstateRecordingEndPoints, color: "#f0ca29" }]}
												valueAxis={{min: revenueDashboardData?.ChartData.EstateTotalChart2Min}}
											/>
										</CardBody>
									</Card>
								</div>

							</div>

							<div className='row'>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Number of Call Recordings (in thousands)" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.RecordingsAdded, color: "#28a745" }]}
												valueAxis={{narrowRange: true}}
											/>
										</CardBody>
									</Card>
								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Call Recording Minutes (in thousands)" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.RecordingMinutesAdded, color: "#28a745" }]}
												valueAxis={{narrowRange: true}}
											/>
										</CardBody>
									</Card>
								</div>
								<div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4'>
									<Card className='card-border bg-white card-hov pt-2 pb-2 rev-chart' fluid>
										<CardHeader className='graph-header mb-2'>
											<Text size="large" weight='bold' content="Recording Storage (TB)" />
										</CardHeader>
										<CardBody>
										<BillingDashChart categories={[{title: "Months", labelRotation: labelRotation, data: revenueDashboardData?.ChartData.CategorySeries}]}
												series={[{type: "column", gap: 4, data: revenueDashboardData?.ChartData.RecordingStorageAdded, color: "#28a745" }]}
												valueAxis={{narrowRange: true}}
											/>
										</CardBody>
									</Card>
								</div>
							</div>

						</div>
						: <Loader />
				}
			</div>
		</div>
	)
}
const ChartDetails = (props) => {

	// const [show, SetShow] = React.useState(true);

	const Close = () => {
		props.CloseChartDetails();
	}


	return (
		<>
			{
				props.showChartPopup ?
					<div className='chart-popup' style={{ top: 0, right: 0 }} >
						<div>Month: {props.data.category}</div>
						<div> Value: {props.data.dataItem}</div>
						{/* Value {props.Value} */}
						{/* <CloseIcon outline size="smaller" /> */}
						<Button onClick={Close} title='Close' className='chart-detail-close' icon={<CloseIcon outline size="smaller" />} iconOnly />
					</div>
					: ""
			}

		</>
	);
}
export default BusinessPerformanceDashboard;